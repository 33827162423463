<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <router-link to="/CadastroPacientes" v-slot="{ href, navigate }">
          <a :href="href" class="menu-link" @click="navigate">
            <v-btn class="mt-2 textStyle" tile color="#1dd1a1">
              <span class="textStyle">Cadastrar novo</span>
            </v-btn>
          </a>
        </router-link>

        <v-toolbar style="height: 6rem">
          <v-toolbar-title
            ><v-btn
              @click="consultaPacientes"
              color="#1dd1a1"
              class="mr-4 mb-2 mt-6"
            >
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >

          <v-text-field
            @keyup.enter.native="consultaPacientes"
            v-model="consulta"
            class="mt-14"
            label="Pesquise pacientes por Data, CPF e nome do paciente"
            color="green"
            filled
            solo-inverted
          ></v-text-field>
        </v-toolbar>

        <div class="row mt-6">
          <div
            style="
              display: flex;
              align-itens: center;
              justify-content: space-between;
            "
            class="col-md-12"
          >
            <b-tooltip :target="`tooltip3-target`" triggers="hover">
              Página anterior
            </b-tooltip>
            <b-tooltip :target="`tooltip4-target`" triggers="hover">
              Próxima página
            </b-tooltip>

            <div class="mt-2">
              <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
            </div>

            <div>
              <v-btn
                :disabled="this.page == 0 || this.page == 1 ? true : false"
                @click="anteriorPage"
                id="tooltip3-target"
                color="#1dd1a1"
                class="mr-1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-left</v-icon
                ></v-btn
              >
              <v-btn
                :disabled="
                  this.page == this.totalPages || this.totalPages == 0
                    ? true
                    : false
                "
                @click="proximoPage"
                id="tooltip4-target"
                color="#1dd1a1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-right</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Nome</th>
                <th class="">CPF</th>
                <th class="">Cartão SUS</th>
                <th class="">Telefone</th>
                <th class="">UBS</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody v-for="paciente in tempPacientes" :key="paciente.id">
              <tr>
                <td @click="visualizarEditarPaciente(paciente.id)">
                  {{ paciente.nome }}
                </td>
                <td @click="visualizarEditarPaciente(paciente.id)">
                  {{ paciente.pessoa.cpf }}
                </td>
                <td @click="visualizarEditarPaciente(paciente.id)">
                  {{ paciente.sus }}
                </td>
                <td @click="visualizarEditarPaciente(paciente.id)">
                  {{ paciente.telefone }}
                </td>
                <td @click="visualizarEditarPaciente(paciente.id)">
                  {{ paciente.ubs }}
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'novoAgendamento',
                      params: {
                        idPaciente: paciente.id,
                        nomePaciente: paciente.nome,
                        flag: 1,
                      },
                    }"
                    v-slot="{ href, navigate }"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn
                        v-b-tooltip.hover.top="'Agendar paciente'"
                        color="#1dd1a1"
                        class="mr-2 mb-2 mt-2"
                      >
                        <v-icon center small color="#FFF"
                          >fas fa-calendar-alt</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>
                  <a
                    :download="`Ficha - ${paciente.nome}`"
                    href="/Files/ambulatorioTCLE.pdf"
                  >
                    <v-btn
                      v-b-tooltip.hover.top="'Ficha de anuência'"
                      color="#1dd1a1"
                      class="mr-2 mb-2 mt-2"
                    >
                      <v-icon center small color="#FFF"
                        >fas fa-file</v-icon
                      ></v-btn
                    >
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          v-if="tempPacientes.length == 0"
          class="d-flex flex-column-fluid flex-center mt-12"
        >
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import jsPDF from "jspdf";
export default {
  name: "PainelPacientes",
  async created() {
    try {
      const response = await ambulatorio.listPacientesPaged(this.page);
      this.totalPages = response.data.total_pages;
      this.pacientes = response.data.pacientes;
      this.tempPacientes = response.data.pacientes;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      totalPages: 1,
      page: 1,
      pacientes: [],
      tempPacientes: [],
      consulta: "",
    };
  },

  methods: {
    gerarPdf: function (nomePaciente) {
      const doc = new jsPDF();

      doc.addImage("media/novaImagens/ambufersa02.png", "PNG", 30, 10, 150, 40);
      doc.setFontSize(15);
      doc.text(`Ficha inicial do paciente: ${nomePaciente}`, 15, 65);

      // salvar documento

      doc.save(`Ficha - ${nomePaciente}.pdf`);
    },
    consultaPacientes: async function () {
      try {
        if (this.consulta == "" || this.consulta == " ") {
          const response = await ambulatorio.listPacientesPaged(1);
          this.pacientes = response.data.pacientes;
          this.tempPacientes = response.data.pacientes;
        } else {
          const data = {
            filter: {
              nome: this.consulta,
              nome_social: this.consulta,
              cpf: this.consulta,
            },
            page: 1,
            size: 10,
          };

          const resultBusca = await ambulatorio.buscaPacientes(data);

          this.pacientes = resultBusca.data.resultado;
          this.tempPacientes = resultBusca.data.resultado;
        }
      } catch (error) {
        console.log(error);
      }
    },

    visualizarEditarPaciente: function (idPaciente) {
      this.$router.push({
        name: "editarDadosPaciente",
        params: { id: idPaciente },
      });
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.listPacientesPaged(this.page);
          this.pacientes = response.data.pacientes;
          this.tempPacientes = response.data.pacientes;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.listPacientesPaged(this.page);
          this.pacientes = response.data.pacientes;
          this.tempPacientes = response.data.pacientes;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Painel de pacientes" }]);
  },
};
</script>

<style>
.textStyle {
  color: white;
  font-size: 1.2em;
}
</style>
